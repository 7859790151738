var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-profile"},[_c('fd-form',{on:{"submit":_vm.updateProfile}},[_c('fd-form-section',{staticClass:"align-items-end",attrs:{"title":"Account"}},[_c('fd-input',{class:_vm.formInputCSS,attrs:{"label":"Email","name":"email","type":"text","disabled":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('div',{class:_vm.formInputCSS},[_c('button',{staticClass:"btn main bordered",attrs:{"type":"button"},on:{"click":function($event){_vm.changePasswordModal.isShown = true}}},[_vm._v(" Change Password ")])])],1),_c('fd-form-section',{attrs:{"title":"Personal Details"}},[_c('fd-input',{class:_vm.formInputCSS,attrs:{"label":"Name","name":"name","type":"text","validators":[_vm.validator.required]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('tel-input',{ref:"tel",staticClass:"col-12 px-1 mb-2 d-block",attrs:{"tel":_vm.form.phoneNo,"label":"Contact No.","required":""},on:{"update:tel":function($event){return _vm.$set(_vm.form, "phoneNo", $event)}},model:{value:(_vm.phoneInput),callback:function ($$v) {_vm.phoneInput=$$v},expression:"phoneInput"}}),_c('image-uploader',{staticClass:"col-12 px-1 mb-2",attrs:{"label":"Avatar","multiple":false},on:{"error":function (error, imageName) {
            _vm.$reportError(error, 'Banker Upload Avatar (Edit Profile)');
          }},model:{value:(_vm.form.avatar),callback:function ($$v) {_vm.$set(_vm.form, "avatar", $$v)},expression:"form.avatar"}})],1),_c('fd-form-section',{attrs:{"title":"Bank Details"}},[_c('fd-input',{class:_vm.formInputCSS,attrs:{"label":"Bank","name":"bank","type":"text","disabled":""},model:{value:(_vm.form.bank),callback:function ($$v) {_vm.$set(_vm.form, "bank", $$v)},expression:"form.bank"}}),_c('fd-input',{class:_vm.formInputCSS,attrs:{"label":"Branch Name","name":"bankBranchName","type":"text"},model:{value:(_vm.form.bankBranchName),callback:function ($$v) {_vm.$set(_vm.form, "bankBranchName", $$v)},expression:"form.bankBranchName"}}),_c('fd-input',{class:_vm.formInputCSS,attrs:{"label":"Office Contact Number","name":"bankContactNumber","type":"text","validators":[_vm.validator.phone]},model:{value:(_vm.form.bankContactNumber),callback:function ($$v) {_vm.$set(_vm.form, "bankContactNumber", $$v)},expression:"form.bankContactNumber"}}),_c('fd-input',{class:_vm.formInputCSS,attrs:{"label":"Branch Address 1","name":"bankBranchAddress1","type":"text"},model:{value:(_vm.form.bankBranchAddress1),callback:function ($$v) {_vm.$set(_vm.form, "bankBranchAddress1", $$v)},expression:"form.bankBranchAddress1"}}),_c('fd-input',{class:_vm.formInputCSS,attrs:{"label":"Branch Address 2","name":"bankBranchAddress2","type":"text"},model:{value:(_vm.form.bankBranchAddress2),callback:function ($$v) {_vm.$set(_vm.form, "bankBranchAddress2", $$v)},expression:"form.bankBranchAddress2"}}),(_vm.form.state !== null)?_c('addresses',{staticClass:"row col-12",attrs:{"isEdit":"","state":_vm.form.state,"city":_vm.form.city,"area":_vm.form.area,"showCountry":false,"stateRequired":false,"cityRequired":false,"areaRequired":false},on:{"update:state":function($event){return _vm.$set(_vm.form, "state", $event)},"update:city":function($event){return _vm.$set(_vm.form, "city", $event)},"update:area":function($event){return _vm.$set(_vm.form, "area", $event)},"change":function (data) {
            _vm.form.bankStateId = data.state.id;
            _vm.form.bankCityId = data.city.id;
            _vm.form.bankAreaId = data.area.id;
          }}}):_vm._e()],1),_c('div',{staticClass:"row justify-content-end mt-3"},[_c('button',{staticClass:"btn medium mr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn main medium"},[_c('i',{staticClass:"fas fa-pen mr-1"}),_vm._v(" Update ")])])],1),_c('modal',{model:{value:(_vm.changePasswordModal.isShown),callback:function ($$v) {_vm.$set(_vm.changePasswordModal, "isShown", $$v)},expression:"changePasswordModal.isShown"}},[_c('fd-form',{staticClass:"change-password card p-3",on:{"submit":_vm.updatePassword}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Change Password")]),_c('fd-input',{staticClass:"col-12 mb-2",attrs:{"label":"New Password","name":"newPassword","type":_vm.showPassword ? 'text' : 'password',"validators":[_vm.validator.required, _vm.validator.password]},scopedSlots:_vm._u([{key:"append-icon",fn:function(){return [_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('i',{staticClass:"fa",class:{
                'fa-eye': !_vm.showPassword,
                'fa-eye-slash': _vm.showPassword
              }})])]},proxy:true}]),model:{value:(_vm.changePasswordModal.form.password),callback:function ($$v) {_vm.$set(_vm.changePasswordModal.form, "password", $$v)},expression:"changePasswordModal.form.password"}}),_c('div',{staticClass:"text-right pt-3"},[_c('button',{staticClass:"btn mr-1",attrs:{"type":"button"},on:{"click":function($event){_vm.changePasswordModal.isShown = false}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn main"},[_vm._v("Update Password")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }