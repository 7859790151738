<template>
  <div class="edit-profile">
    <fd-form @submit="updateProfile">
      <!-- ACCOUNT -->
      <fd-form-section title="Account" class="align-items-end">
        <fd-input
          v-model="form.email"
          :class="formInputCSS"
          label="Email"
          name="email"
          type="text"
          disabled
        >
        </fd-input>
        <div :class="formInputCSS">
          <button
            type="button"
            class="btn main bordered"
            @click="changePasswordModal.isShown = true"
          >
            Change Password
          </button>
        </div>
      </fd-form-section>
      <!-- PERSONAL DETAILS -->
      <fd-form-section title="Personal Details">
        <fd-input
          v-model="form.name"
          :class="formInputCSS"
          label="Name"
          name="name"
          type="text"
          :validators="[validator.required]"
        >
        </fd-input>
        <tel-input
          ref="tel"
          v-model="phoneInput"
          :tel.sync="form.phoneNo"
          class="col-12 px-1 mb-2 d-block"
          label="Contact No."
          required
        >
        </tel-input>
        <image-uploader
          v-model="form.avatar"
          class="col-12 px-1 mb-2"
          label="Avatar"
          :multiple="false"
          @error="
            (error, imageName) => {
              $reportError(error, 'Banker Upload Avatar (Edit Profile)');
            }
          "
        >
        </image-uploader>
      </fd-form-section>

      <!-- BANK -->
      <fd-form-section title="Bank Details">
        <fd-input
          v-model="form.bank"
          :class="formInputCSS"
          label="Bank"
          name="bank"
          type="text"
          disabled
        >
        </fd-input>
        <fd-input
          v-model="form.bankBranchName"
          :class="formInputCSS"
          label="Branch Name"
          name="bankBranchName"
          type="text"
        >
        </fd-input>
        <fd-input
          v-model="form.bankContactNumber"
          :class="formInputCSS"
          label="Office Contact Number"
          name="bankContactNumber"
          type="text"
          :validators="[validator.phone]"
        >
        </fd-input>
        <fd-input
          v-model="form.bankBranchAddress1"
          :class="formInputCSS"
          label="Branch Address 1"
          name="bankBranchAddress1"
          type="text"
        >
        </fd-input>
        <fd-input
          v-model="form.bankBranchAddress2"
          :class="formInputCSS"
          label="Branch Address 2"
          name="bankBranchAddress2"
          type="text"
        >
        </fd-input>
        <addresses
          v-if="form.state !== null"
          isEdit
          :state.sync="form.state"
          :city.sync="form.city"
          :area.sync="form.area"
          class="row col-12"
          :showCountry="false"
          :stateRequired="false"
          :cityRequired="false"
          :areaRequired="false"
          @change="
            (data) => {
              form.bankStateId = data.state.id;
              form.bankCityId = data.city.id;
              form.bankAreaId = data.area.id;
            }
          "
        ></addresses>
      </fd-form-section>

      <div class="row justify-content-end mt-3">
        <button type="button" class="btn medium mr-1" @click="$emit('cancel')">
          Cancel
        </button>
        <button class="btn main medium">
          <i class="fas fa-pen mr-1"></i> Update
        </button>
      </div>
    </fd-form>

    <!-- Modal: Change Password -->
    <modal v-model="changePasswordModal.isShown">
      <fd-form class="change-password card p-3" @submit="updatePassword">
        <h3 class="mb-3">Change Password</h3>

        <fd-input
          v-model="changePasswordModal.form.password"
          class="col-12 mb-2"
          label="New Password"
          name="newPassword"
          :type="showPassword ? 'text' : 'password'"
          :validators="[validator.required, validator.password]"
        >
          <template #append-icon>
            <span class="cursor-pointer" @click="showPassword = !showPassword">
              <i
                class="fa"
                :class="{
                  'fa-eye': !showPassword,
                  'fa-eye-slash': showPassword
                }"
              ></i>
            </span>
          </template>
        </fd-input>

        <div class="text-right pt-3">
          <button
            type="button"
            class="btn mr-1"
            @click="changePasswordModal.isShown = false"
          >
            Cancel
          </button>
          <button class="btn main">Update Password</button>
        </div>
      </fd-form>
    </modal>
  </div>
</template>

<script>
import TelInput from "@/modules/User/components/TelInput";
import { ProfileModel } from "@/models";

import {
  required,
  phone,
  password
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {
    TelInput,
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader"),
    Addresses: () => import("@/components/GlobalComponents/Address")
  },
  mixins: [],
  props: {
    banker: {
      type: [Object, Function]
    }
  },
  data: function () {
    return {
      areaOptions: [],
      cityOptions: [],
      stateOptions: [],

      form: {},

      formInputCSS: "col-12 sm-col-6 md-col-3 px-1 mb-2",

      validator: {
        required: required,
        phone: phone,
        password: password
      },

      phoneInput: "",
      showPassword: false,

      changePasswordModal: {
        isShown: false,
        form: {
          passsword: ""
        }
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.form = this.banker;
    this.$refs.tel.setTel(this.form);
  },
  methods: {
    pwdUpdateSuccess(success) {
      if (success) {
        this.changePasswordModal.isShown = false;
        this.changePasswordModal.form.passsword = "";
      }
    },
    updateProfile() {
      this.$emit("updateProfile", ProfileModel.putProfilePayload(this.form));
    },
    updatePassword() {
      this.$emit(
        "updatePassword",
        this.changePasswordModal.form,
        this.pwdUpdateSuccess
      );
    }
  }
};
</script>

<style lang="scss">
.edit-profile {
  .status {
    width: fit-content;
    padding: 8px 24px;
    border-radius: 50em;
    color: white;
    background: $color-success;
    &.active {
      background: $color-success;
    }
    &.inactive {
      background: $color-warn;
    }
  }

  .avatar-placeholder {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #ddd;
    text-align: center;
    i {
      font-size: 80px;
      line-height: 1.25;
      color: white;
    }
  }
  .avatar-img {
    @include image(cover);
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
    color: #444;
  }

  .email {
    color: #777;
  }
}
</style>
